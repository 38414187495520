import { useState } from "react";
import TaskIcon from "../../icons/task";
import authFirebase from "../../services/authFirebase";
import Modal from "../modal/modal";
import styles from './header.module.css'
type HeaderProps = {
    displayName: string | null | undefined
  }

  
export default function Header(props: HeaderProps) {
    const {
        displayName
    } = props
    return (
        <div className={styles['header']}>
            <div className={styles['header-logo']}>
                <TaskIcon />
                My Dailies
            </div>
            <div className={styles['user']}>
                {displayName}
                <img src={process.env.PUBLIC_URL + '/user-example.jpeg'} alt="user" />
            </div>
        </div>
    )    
}