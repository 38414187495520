import styles from './profileSettings.module.css'
import { updateDisplayName, UserData } from '../../../api'
import { CustomInput } from '../../custom-input/customInput'
import CustomButton from '../../custom-button/custombutton'
import { FormEvent, useState } from 'react'
import { FormType } from '../../../models/form'
import Validate from '../../../utils/validate'
import authFirebase from '../../../services/authFirebase'
import Modal from '../../modal/modal'

interface ProfileSettingsProps {
    user: UserData | undefined
}

interface DisplayNameProps {
    form: FormType,
    onChange: (e: FormEvent<HTMLInputElement>, name: string) => void;
}

interface ActionButtonProps {
    user: UserData | undefined
}

export default function ProfileSettings(props: ProfileSettingsProps) {
    const {
        user
    } = props

    const [form, setForm] = useState<FormType>({
        name: {
            name: 'name',
            value: user?.displayName ?? '',
            error: '',
            valid: false,
            rules: ['name', 'not_empty'],
        },
    })
    
    const [isFirst, setIsFirst] = useState<boolean>(false)
    const [disabledSubmit, setDisableSubmit] = useState<boolean>(false)

    const handleChange = (e: FormEvent<HTMLInputElement>, name: string) => {
        const target = e.target as HTMLInputElement
        const newVal = target.value
        
        const newState: FormType = { ...form };
        newState[name].value = newVal;
        let validationObj = Validate(newState[name].rules, newVal)
        newState[name].valid = validationObj.isValid
        if(isFirst) {
            newState[name].error = validationObj.msg
        }
        setForm(newState)
    }
    
    const onSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        const newState = {...form}
        let isValid = true;
        setIsFirst(true)
        for (const key in form) {   
            let validationObj = Validate(newState[key].rules, newState[key].value)
            
            newState[key].valid = validationObj.isValid
            newState[key].error = validationObj.msg

            if(!newState[key].valid) {
                isValid = false
            }
        }
        setForm(newState)
            
        if(isValid) {
            const payload = {
                displayName: form.name.value
            }
            submitData(payload)
        }
    }

    const submitData = async (payload: object) : Promise<void> => {
        if(!user) return
        const res = await updateDisplayName(user, payload)
        if(res.ok) {
            setDisableSubmit(true)
        }
    }


    return (
        <div className={styles['profile-settings']}>
            <h1 className={styles['title']}>Profile Settings</h1>
            <RenderDisplayName 
                    form={form}
                    onChange={handleChange}
                />
            
            <div className={styles['container']}>
                <CustomButton className={styles['save-custom-button']}
                            onClick={onSubmit}
                            disabled={disabledSubmit}>
                    Save Changes
                </CustomButton>  
            </div>

            <RenderActionButtons user={user}/>
        </div>
    )
}


function RenderDisplayName(props: DisplayNameProps) {
    const {
        form,
        onChange
    } = props
    return (
        <div className={styles['display-name-component']}>
            <CustomInput className={styles['custom-input']}
                         value={form.name.value}
                         onChange={onChange}
                         placeholder={'Display name'}
                         name={form.name.name}
                         error={form.name.error}
                         />
        </div>
    )
}

function RenderActionButtons(props: ActionButtonProps) {
    const {user} = props

    const [isModalSignOutOpen, setIsModalSignOutOpen] = useState<boolean>(false)
    const [isModalDeleteUserOpen, setIsModalDeleteUserOpen] = useState<boolean>(false)
   
    const modalDetailsSignOut = {
        title: 'Are you sure you want to sign out?',
        buttonText1: 'Sign out',
        buttonText2: 'Cancel',
        onClickButton1: () => {
            authFirebase.signOut()
        },
        onClickButton2: () => {
            setIsModalSignOutOpen(false)
        },
    }

    const modalDetailsDeleteUser = {
        title: 'Are you sure you want to delete the user?',
        buttonText1: 'Delete',
        buttonText2: 'Cancel',
        onClickButton1: () => {
            authFirebase.removeUser(user)
        },
        onClickButton2: () => {
            setIsModalDeleteUserOpen(false)
        },
    }

    return (
        <div className={styles['action-buttons-container']}>
            <CustomButton className={styles['action-custom-button']}
                          buttonColor='light'
                          onClick={() => setIsModalSignOutOpen(true)}>
                Sign out
            </CustomButton> 

            <CustomButton className={styles['action-custom-button']}
                          buttonColor='light'
                          onClick={() => setIsModalDeleteUserOpen(true)}>
                Delete user
            </CustomButton> 
            <Modal 
                isOpen={isModalSignOutOpen} 
                onClose={() => setIsModalSignOutOpen(false)}
                modalType={'basic'}
                modalDetails={modalDetailsSignOut}
            />
            <Modal 
                isOpen={isModalDeleteUserOpen} 
                onClose={() => setIsModalDeleteUserOpen(false)}
                modalType={'basic'}
                modalDetails={modalDetailsDeleteUser}
            />
        </div>
    )
}