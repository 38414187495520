import { useEffect, useState } from 'react'
import Daily from '../components/daily/daily'
import DatePicker from '../components/datePicker/datePicker'
import Header from '../components/header/header'
import SideNav from '../components/sideNav/sideNav'
import { DBType, DailyType, UserData, createOrUpdateDaily, getAllDailies, getUser } from '../api'
import Settings from '../components/settings/settings'
import { getUserWithRetry } from '../api'
import { useLocation, useNavigate } from 'react-router-dom'
import { SETTINGS } from '../constants/routes'

type MainProps = {
  userId: string,
}
type ContentProps = {
  children: React.ReactNode
}

const today = new Date().toISOString().split('T')[0]
function generateDates(): string[] {
  const today = new Date()
  const startDate = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  )
  const endDate = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate()
  )

  const dates: string[] = []
  let currentDate = startDate

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`

    dates.push(formattedDate)
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

const dates = generateDates()
export default function Main({ userId} : MainProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const isSettingsRoute = location.pathname === SETTINGS;

  const [dailies, setDailies] = useState<DBType | undefined>(undefined)
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    undefined
  )
  const [forceScroll, setForceScroll] = useState(false)
  const [currUser, setCurrUser] = useState<UserData | undefined>(undefined)
  const [showSettings, setShowSettings] = useState<boolean>(isSettingsRoute)


  useEffect(() => {
      getData()
  }, [])

  useEffect(() => {
    if (dailies && !selectedDate) {
      setSelectedDate(today)
    }
  }, [dailies, selectedDate])

  useEffect(() => {
    if (forceScroll) {
      setForceScroll(false)
    }
  }, [forceScroll])

  

  const getData = async () => {
    const user = await getUserWithRetry(userId)
    setCurrUser(user)
    const dailies = await getAllDailies(user)
    setDailies(dailies)
  }
 
  const handleEdit = (daily: DailyType) => {
    if(!currUser) {
      return
    } 
    createOrUpdateDaily(currUser, daily).then(setDailies)
  }

  const selectToday = () => {
    setSelectedDate(today)
    if (selectedDate === today) {
      setForceScroll(true)
    }
  }
  const toggleSetting = () => {
    setShowSettings((prevSettings) => {
      if(!prevSettings) navigate(SETTINGS)
      else navigate(-1)   
      return !prevSettings
    })
  }

  function getPreviousDate(dateString: string): string {
    const date = new Date(dateString)
    date.setDate(date.getDate() - 1)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const previousDate = `${year}-${month}-${day}`

    return previousDate
  }

  function getNextDate(dateString: string): string {
    const date = new Date(dateString)
    date.setDate(date.getDate() + 1)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const nextDate = `${year}-${month}-${day}`

    return nextDate
  }
  
  if (!dailies || !selectedDate) return null
  
  return (
    <>
      <Header displayName={currUser?.displayName}/>
      <div className={'main'}>
        <div className={'main-content'}>
          <SideNav 
            isToday={selectedDate === today} 
            selectToday={selectToday}
            toggleSetting={toggleSetting}
          />
          <DatePicker
            dates={dates}
            selected={selectedDate}
            onSelectDate={setSelectedDate}
            forceScroll={forceScroll}
          />
          <Content>
            <Settings 
              user={currUser}
              visibility={showSettings}
              toggleSetting={toggleSetting}
            />  
            <Daily
              daily={{ date: selectedDate, text: dailies[selectedDate] || '' }}
              onChange={handleEdit}
              forward={() => setSelectedDate(getNextDate(selectedDate))}
              backwards={() => setSelectedDate(getPreviousDate(selectedDate))}
            />
          </Content>
          
        </div>
      </div>
    </>
  )
}

function Content(props: ContentProps) {
  const {
    children
  } = props

  return <div className={'content'}>{children}</div>
}