import React from 'react';
import styles from './tabs.module.css';

interface TabsProps<T extends string> {
  tabs: { name: T; icon: string }[]; // Each tab now has a name and an icon
  onTabSelect: (tab: T) => void;
  selectedTab: T;
}

export default function Tabs<T extends string>({ tabs, onTabSelect, selectedTab }: TabsProps<T>) {
  return (
    <div className={styles['tab-container']}>
      {tabs.map(({ name, icon }) => (
        <button
          key={name}
          className={`${styles['tab']} ${selectedTab === name ? styles['active'] : ''}`}
          onClick={() => onTabSelect(name)}
        >
          <img className={styles['icon']} src={icon}/>
          <span className={styles['tab-text']}>{name.charAt(0).toUpperCase() + name.slice(1)}</span>
        </button>
      ))}
    </div>
  );
}