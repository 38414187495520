import { useEffect, useState } from 'react'
import './App.css'
import Main from './pages/main'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { FORGOT, SIGN_IN, SIGN_UP, ROOT, SETTINGS } from './constants/routes'
import { User, onAuthStateChanged } from 'firebase/auth'
import { auth } from './firebaseConfig'
import Signin from './pages/authentication/signin/signin'
import Signup from './pages/authentication/signup/signup'
import AuthenticationLayout from './pages/authentication/layout'
import Forgot from './pages/authentication/forgot'

function App() {
  const [user, setUser] = useState<User | null>(null)
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  /*
    Listening to the user state changed
  */
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        setIsEmailVerified(user.emailVerified)
      } else {
        setUser(null)
        setIsEmailVerified(false)
      }
      setLoading(false)
    })
    return () => unsubscribe()
  }, [])

  /*
    Poll every 5 seconds
    Stop polling after 1 minute
  */
  useEffect(() => {
    const intervalDuration = 5000
    const maxDuration = 60000
    let elapsed = 0
    const intervalId = setInterval(() => {
      const user = auth.currentUser
      if (user) {
        user.reload().then(() => {
          if (user.emailVerified) {
            setIsEmailVerified(true)
            clearInterval(intervalId)
          }
        })
      }
      elapsed += intervalDuration
      if (elapsed >= maxDuration) {
        clearInterval(intervalId)
      }
    }, intervalDuration)

    return () => clearInterval(intervalId)
  }, [])

  if (loading) {
    return <></>
  }

  const isLoggedIn: boolean = !!user && isEmailVerified
  
  return (
    <BrowserRouter>
      {!isLoggedIn ? (
        <Routes>
          <Route element={<AuthenticationLayout user={user} />}>
            <Route path={SIGN_IN} element={<Signin user={user} />} />
            <Route path={SIGN_UP} element={<Signup user={user} />} />
            <Route path={FORGOT} element={<Forgot />} />
            <Route path={'*'} element={<Navigate to={SIGN_IN} />} />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path={'*'} element={<Navigate to={ROOT} />} />
          <Route path={ROOT} element={<Main userId={user?.uid ?? ''} />} />
          <Route path={SETTINGS} element={<Main userId={user?.uid ?? ''} />} />
        </Routes>
      )}
    </BrowserRouter>
  )
}

export default App
