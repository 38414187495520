import styles from './seperate.module.css'

export default function Seperate() {
    return (
        <div className={styles['seperate-container']}>

                <div className={styles['horizontal-line']}/>
                <span className={styles['text']}>OR</span>
                <div className={styles['horizontal-line']}/>
                
        </div>
    )
}